<!--企业信息  -->
<template>
  <div class="userinfo">
    <div class="userinfo-title-list">
      <div class="userinfo-title-item userinfo-cursor" :class="[item.id == selectId ? 'userinfo-title-active':'']"
        @click="clickselectBox($event)" :data-id="item.id" v-for="item in selectTitle" :key="item.id">{{item.name}}
      </div>
    </div>

    <!-- 学员信息 -->
    <div v-if="selectId == 1">
      <div class="userinfo-student">
        <div class="item">
			<div class="label">联系电话：</div>
			<div class="value">133 7994 2032</div>
		</div>
		<div class="item">
			<div class="label">座机电话：</div>
			<div class="value">0898-66815360</div>
		</div>
      </div>
    </div>
  </div>
</template>

<script>
  import interfaces from '../../../utils/interface.js'
  export default {
    components: {},
    data() {
      return {
        selectId: 1, //选择切换
        //切换信息
        selectTitle: [{
          name: '联系客服',
          id: 1
        }],
        UserInfo: {
          email: '',
          mailing_addr: '',
          id_photo1: '',
          id_photo2: '',
          diploma_photo: '',
          tel: '',
          enterprise_name: '',
		  address:'',
        }, // 个人信息
        // 性别
        sexList: [{
          value: '0',
          label: '男'
        }, {
          value: '1',
          label: '女'
        }],
        sex_txt: '',
        // 学历
        educationNameList: [{
          value: '0',
          label: '男'
        }, {
          value: '1',
          label: '女'
        }],
        education_cate_name: '',
        uploadImg: interfaces.uploadImages, //上传图片接口地址
        closeDisabled: true, //不能修改
        certificateList: [], //发证记录
        pageCertificate: 1, //发证页数
        limitCertificate: 10, //发证条数
        pagenvIoiceLists: 1, //发票列表页数
        limitInvoiceLists: 10, //发票列表条数
        invoiceLists: [], //发票记录

        loading: false,
        page: 1,
        limit: 10,
        totalpage: 0,
        count: 0,
        trainObjectArr: [],
        trainObject: '',
        curriculumCateArr: [],
        curriculumCate: '',
        trainTypeArr: [],
        trainType: '',
        keyword: '',
        list: [],
        activeName: 'first',
        status: '',
      };
    },
    created() {
      let that = this;
      that.getUserInfo(); //获取个人信息
      that.getCertificateList(); //发证记录
      that.getUserInvoiceList(); //开票信息
      if(that.$route.query.selectId == 2){
       that.selectId = that.$route.query.selectId;
      }

    },
    methods: {
      //选择切换
      clickselectBox(e) {
        let that = this;
        that.selectId = e.target.dataset.id;
      },
      //获取个人信息
      getUserInfo() {
        let that = this;
        that.$request.getUserInfo().then((res) => {
          if (res.code == 200) {
            that.UserInfo = res.datas;
          }
        });
      },
      //修改个人信息
      getUpdateInfo() {
        let that = this;
        let param = {
          email: that.UserInfo.email,
          mailing_addr: that.UserInfo.mailing_addr,
          id_photo1: that.UserInfo.id_photo1,
          id_photo2: that.UserInfo.id_photo2,
          diploma_photo: that.UserInfo.diploma_photo,
          tel: that.UserInfo.tel,
          enterprise_name: that.UserInfo.enterprise_name,
		  address:that.UserInfo.address,
        };
        that.$request.getUpdateInfo(param).then((res) => {
          if (res.code == 200) {
            // that.UserInfo = res.datas;
			that.getUserInfo();
          } else {
            that.$myMessage.warning(res.msg);
            return false;
          }
        });
        
      },
      // 修改资料
      editSubmit() {
        let that = this;
        that.closeDisabled = false;
      },
      // 保存修改
      sureSubmit() {
        let that = this;
        that.closeDisabled = true;
        that.getUpdateInfo();
      },
      // 发证记录
      getCertificateList() {
        let that = this;
        let param = {
          page: that.pageCertificate,
          limit: that.limitCertificate,
        };
        that.$request.getCertificateList(param).then((res) => {
          if (res.code == 200) {
            that.certificateList = res.datas;
          }
        });
      },
      //获取发票列表
      getUserInvoiceList() {
        let that = this;
        that.loading = true;
        let param = {
          page: that.pagenvIoiceLists,
          limit: that.limitInvoiceLists,
        };
        that.$request.getUserInvoiceList(param).then((res) => {
          if (res.code == 200) {
            that.invoiceLists = res.datas;
            if (that.invoiceLists.invoice_id1 == 1) {
              that.invoiceLists.invoice_name1 == '电子发票'
            } else {
              that.invoiceLists.invoice_name1 == '专用发票'
            }
            that.page = that.page + 1;
            that.totalpage = res.total_page;
            that.count = res.count;
            that.loading = false;
          }
        });
      },
      // 取消修改
      closeSubmit(){
        let that = this;
        that.closeDisabled = true;
      },
      // 删除
      handleDel(index, row) {

      },
      // 编辑
      handleEdit(index, row) {
        let that = this;
        let invoiceId = row.order_invoice_id;
        that.$router.push({
          path: '/user/invoice/info',
          query: {
            order_invoice_id: invoiceId
          }
        });
      },
}
  };
</script>

<style scoped>
  .userinfo {
    margin: 1.5rem auto;
    padding: 2rem;
    width: 87%;
    height: calc(100vh - 7rem);
    background-color: #fff;
    border-radius: 5px;
  }

  .userinfo-cursor {
    cursor: pointer;
  }

  .userinfo-title-list {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 3px solid #0074FF;
    text-align: center;
  }

  .userinfo-title-item {
    width: 100%;
    line-height: 3;
	font-size: 24px;
  }

  .userinfo-title-item:not(:last-child) {
    border-right: solid #0074FF 3px;
  }

  .userinfo-title-active {
    color: #fff;
    background-color: #0074FF;
  }

  .userinfo-student {
    overflow-y: auto;
    margin: 2rem 3rem;
    height: 65vh;
  }
 .userinfo-student .item{
	 display: flex;
	 flex-direction: row;
	 margin-bottom: 2rem;
 }
 .userinfo-student .item .label{
	 width: 150px;
	 font-size: 24px;
	 color: #333;
 }
 .userinfo-student .item .value{
 	 font-size: 24px;
	 color: #333;
 }
</style>
